import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { TooltipProperties } from './tooltip.types';
import { Text } from 'flex';
import clsx from 'clsx';

export function Tooltip({
  idTooltip,
  children,
  contentTooltip,
  isClickable = false,
  alwaysCanDisplayTooltip = true,
  tooltipPlace = 'top',
  variant = 'dark',
  childrenIsOverflowHidden = true,
}: TooltipProperties) {
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    function checkTruncation() {
      if (anchorRef.current) {
        setIsTruncated(anchorRef.current.offsetWidth < anchorRef.current.scrollWidth);
      }
    }

    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, [children]);

  const shouldShowTooltip = alwaysCanDisplayTooltip || isTruncated;

  const tooltipContent = useMemo(() => {
    if (typeof contentTooltip === 'string' || typeof contentTooltip === 'number') {
      return (
        <Text className={clsx('font-secondary', variant !== 'light' ? 'text-white' : 'text-black')}>
          {contentTooltip ?? ''}
        </Text>
      );
    }
    return contentTooltip;
  }, [contentTooltip]);

    const customLightTooltipStyles = {
    boxShadow: variant === 'light' ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
    border: variant === 'light' ? 'none' : '1px solid #ccc',
    zIndex: 9999,
  };

  return (
    <>
      <a
        ref={anchorRef}
        data-tooltip-id={idTooltip}
        data-tooltip-place={tooltipPlace}
        data-tooltip-variant={variant}
        className={clsx(
          childrenIsOverflowHidden && 'overflow-hidden truncate',
          'font-secondary text-sm',
        )}
      >
        {children}
      </a>
      {shouldShowTooltip && (
        <ReactTooltip
          id={idTooltip}
          opacity={1}
          clickable={isClickable}
          place="top"
          style={customLightTooltipStyles}
        >
          {tooltipContent}
        </ReactTooltip>
      )}
    </>
  );
}
