import React, { useState, useRef, useEffect, useMemo } from 'react';
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { Text } from '../Text';
import { GroupedOption, SearchSelectProperties, SelectOption } from './searchselect.types';

const defaultTranslationKeys = {
  noOptionsMessage: 'No options',
  placeholder: 'Select...',
  moreValues: 'and {count} more',
  search: 'Search...',
};

export type TSelectTranslationKeys = Record<keyof typeof defaultTranslationKeys, string>;

const SearchSelect: React.FC<SearchSelectProperties> = ({
  multiple = false,
  label,
  options,
  hasGroup = false,
  searchFromQuery = false,
  searchLocal = true,
  hasInfiniteScroll = false,
  handleInfiniteScroll,
  searchQuery,
  clearable = true,
  infiniteMax,
  currentLimit,
  translations,
  onValueChange,
  applyDefaultValueOnClickCross = false,
  defaultValue = { label: '', value: '' },
  values,
  className = '',
}) => {
  const initializeSelectedValue = (): string | string[] => {
    if (multiple) {
      return Array.isArray(values) ? values.map((v) => v.value) : [];
    }

    return !Array.isArray(values) && values?.value ? values.value : '';
  };

  const [selectedValue, setSelectedValue] = useState<string | string[]>(initializeSelectedValue());

  useEffect(() => {
    if (values) {
      setSelectedValue(initializeSelectedValue());
    }
  }, [values]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  // merge the default translations with the user's translations if there are any
  const t: typeof defaultTranslationKeys = useMemo(
    () => ({
      ...defaultTranslationKeys,
      ...translations,
    }),
    [translations],
  );

  const handleChange = (value: SelectOption | SelectOption[]) => {
    if (multiple) {
      let newValue: string[] = [];

      if (Array.isArray(selectedValue)) {
        if (Array.isArray(value)) {
          // Cas : valeur multiple
          newValue = value.map((v) => v.value);
        } else if (selectedValue.includes((value as SelectOption).value)) {
          // Cas : suppression d'une valeur existante
          newValue = selectedValue.filter((val) => val !== (value as SelectOption).value);
        } else {
          // Cas : ajout d'une nouvelle valeur
          newValue = [...selectedValue, (value as SelectOption).value];
        }
      } else {
        // Cas : initialisation d'une liste multiple
        newValue = [(value as SelectOption).value];
      }

      setSelectedValue(newValue);
      // @ts-ignore
      const newValueForChange: SelectOption[] = Array.isArray(value)
        ? value.map((val) => ({ label: val, value: val }))
        : newValue.map((val) => ({ label: val, value: val }));

      // @ts-ignore - We know that the value is a SelectOption[]
      onValueChange(newValueForChange);
    } else {
      // Mode simple (non multiple)
      if (!Array.isArray(value)) {
        setSelectedValue(value.value);
        // @ts-ignore - We know that the value is a SelectOption
        onValueChange({ label: value.label, value: value.value });
      }
    }
  };

  const isSelected = (value: string) => {
    return multiple
      ? Array.isArray(selectedValue) && selectedValue.includes(value)
      : selectedValue === value;
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchFromQuery && searchQuery) {
      searchQuery(event.target.value);
    }

    setSearchTerm(event.target.value);
  };

  function filterOptions(searchTerm: string): (SelectOption | GroupedOption)[] {
    if (hasGroup) {
      return (options as GroupedOption[])
        ?.map((group) => ({
          ...group,
          options: group?.options?.filter((option) =>
            option?.label?.toLowerCase().includes(searchTerm?.toLowerCase()),
          ),
        }))
        .filter((group) => group?.options?.length > 0);
    }

    return (options as SelectOption[])?.filter((option) =>
      option?.label?.toLowerCase().includes(searchTerm?.toLowerCase()),
    );
  }

  function getOptionLabel(value: string | string[]): string {
    if (Array.isArray(value)) {
      if (value.length === 0) return t.placeholder;

      const firstValue = value[0];
      let firstLabel = '';

      if (hasGroup) {
        // Group label
        for (const group of options as GroupedOption[]) {
          const option = group.options.find((option) => option.value === firstValue);
          if (option) {
            firstLabel = option.label;
            break;
          }
        }
      } else {
        // Label when not in a group
        const option = (options as SelectOption[])?.find((option) => option.value === firstValue);
        if (option) {
          firstLabel = option.label;
        }
      }

      const additionalCount = value.length - 1;
      if (additionalCount > 0) {
        return `${firstLabel} ${t.moreValues.replace('{count}', `${additionalCount}`)}`;
      }

      return firstLabel;
    }

    if (hasGroup) {
      // Group label
      for (const group of options as GroupedOption[]) {
        const option = group.options.find((option) => option.value === value);
        if (option) {
          return option.label;
        }
      }
    } else {
      // Label when not in a group
      const option = (options as SelectOption[]).find((option) => option.value === value);
      if (option) {
        return option.label;
      }
    }

    return t.placeholder;
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredOptions = filterOptions(searchTerm);

  useEffect(() => {
    if (
      hasInfiniteScroll &&
      listRef.current &&
      currentLimit &&
      infiniteMax &&
      infiniteMax > currentLimit
    ) {
      const handleScroll = () => {
        const previousScrollTop = listRef.current?.scrollTop || 0;

        if (
          listRef.current &&
          listRef.current.scrollTop + listRef.current.clientHeight >= listRef.current.scrollHeight
        ) {
          handleInfiniteScroll && handleInfiniteScroll();
        }

        if (listRef.current) {
          listRef.current.scrollTop = previousScrollTop;
        }
      };

      if (isOpen) {
        listRef.current?.addEventListener('scroll', handleScroll);

        return () => {
          listRef.current?.removeEventListener('scroll', handleScroll);
        };
      }
    }
  }, [hasInfiniteScroll, handleInfiniteScroll, infiniteMax, currentLimit, isOpen]);

  function renderOptions(
    filteredOptions: (SelectOption | GroupedOption)[],
    selectedValue: string | string[],
    handleChange: (value: SelectOption | SelectOption[]) => void,
  ) {
    if (filteredOptions.length === 0) {
      return (
        <ul className="max-h-60 overflow-auto">
          <li className="p-2">
            <Text>{t.noOptionsMessage}</Text>
          </li>
        </ul>
      );
    }

    return (
      <ul className="max-h-60 overflow-auto" ref={listRef}>
        {filteredOptions.map((option) => {
          if ('groupName' in option) {
            // Option is a group
            return (
              <div key={option.groupName}>
                <div className="px-3 py-1">
                  <Text strong="semi" size="base" className="uppercase !text-primary-700">
                    {option.groupName}
                  </Text>
                </div>
                {option.options.map((groupOption) => (
                  <li
                    key={groupOption.value}
                    className={clsx('cursor-pointer py-2', 'hover:bg-slate-100')}
                    onClick={() => handleChange(groupOption)}
                  >
                    <div className="flex flex-row items-center">
                      <Text
                        className={`ml-5 block truncate ${
                          selectedValue === groupOption.value
                            ? 'font-semibold text-gray-900'
                            : 'text-gray-900'
                        }`}
                      >
                        {groupOption.label}
                      </Text>
                      {isSelected(groupOption.value) && (
                        <CheckIcon className="ml-auto mr-3 h-4 w-4 font-semibold text-gray-900" />
                      )}
                    </div>
                  </li>
                ))}
              </div>
            );
          }

          // Option is a single option
          return (
            <li
              key={option.value}
              className={clsx('cursor-pointer py-2', 'hover:bg-slate-100')}
              onClick={() => handleChange(option)}
            >
              <div className="flex flex-row items-center justify-between">
                <Text
                  className={`ml-3 block truncate ${
                    isSelected(option.value) ? 'font-semibold text-gray-900' : 'text-gray-900'
                  }`}
                >
                  {option.label}
                </Text>
                {option?.customLabel && <>{option?.customLabel}</>}
                {isSelected(option.value) && !option?.customLabel && (
                  <CheckIcon className="ml-auto mr-3 h-4 w-4 font-semibold text-gray-900" />
                )}
              </div>
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <div className="relative w-full" ref={dropdownRef}>
      {label && (
        <Text type="secondary" size="base" strong="medium" className="mb-1">
          {label}
        </Text>
      )}
      <div
        tabIndex={0}
        className={clsx(
          `${className} border-1 block inline-flex min-w-60 cursor-pointer rounded-lg rounded-md border border-solid border-gray-300 border-slate-300 bg-white px-2 py-2.5 text-base text-gray-600 ring-0 transition-all`,
          'hover:bg-slate-50',
          'focus:border focus:border-solid focus:border-indigo-300 focus:outline-none focus:ring-2  focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-slate-50',
          'w-full',
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex w-full flex-row items-center justify-between">
          <Text>{getOptionLabel(selectedValue)}</Text>
          {clearable && selectedValue && selectedValue.length > 0 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (applyDefaultValueOnClickCross) {
                  // @ts-ignore
                  setSelectedValue(defaultValue.value);
                  // @ts-ignore
                  onValueChange(defaultValue);
                } else {
                  setSelectedValue(multiple ? [] : '');
                  setSearchTerm('');
                  // @ts-ignore - We know that the value is supposed to be like that
                  onValueChange(multiple ? [] : { label: '', value: '' });
                }
              }}
              className="ml-auto"
            >
              <XMarkIcon className="h-5 w-5 font-bold text-gray-900 transition-colors duration-200 hover:text-gray-700" />
            </button>
          )}
          <div className="flex flex-row items-center">
            <span className="mx-2 h-5 border-l-[1px] border-solid border-slate-400" />
            <ChevronUpDownIcon className="h-4 w-4 text-slate-500" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="absolute z-10 mt-2 w-full rounded-md border border-solid border-slate-200 bg-white font-body shadow-lg">
          {(searchLocal || searchFromQuery) && (
            <div className="p-2">
              <input
                type="text"
                className="block w-full rounded-lg border border-solid border-slate-300 px-3 py-2 text-sm placeholder-slate-300 before:absolute before:inset-0 before:z-[1] focus:border-primary-500 focus:ring-primary-500"
                placeholder={t.search}
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
          )}
          <div className="max-h-60">
            {renderOptions(filteredOptions, selectedValue, handleChange)}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchSelect;
